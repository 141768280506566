/*
  Project: DelMorgan & Co.
  Author: xfive
 */

/* eslint-disable no-unused-vars,func-names,prefer-const,no-param-reassign,no-undef,no-useless-escape */
import Swiper from 'swiper/swiper-bundle.min';
import lity from 'lity';
import jQuery from 'jquery';

const $ = jQuery;

/** ******************
 * JS from designer
 ****************** */

$(document).ready(function () {
  /* -------- HEADER MENU -------*/
  $(window).scroll(function () {
    if ($(this).scrollTop() > 40) {
      $('.menunavbar').addClass('menufixed');
      $('.svghide').hide();
      $('.svgshow').show();
      $('.logo').addClass('smalllogo');
      $('body').addClass('bodypopupfixed');
    } else {
      $('.menunavbar').removeClass('menufixed');
      $('.svghide').show();
      $('.svgshow').hide();
      $('.logo').removeClass('smalllogo');
      $('body').removeClass('bodypopupfixed');
    }
  });

  $('.sidebarIconToggle').click(function () {
    $(this).toggleClass('sidebarclose');

    $('.sidebar').toggle();

    $('.sidebar').toggleClass('sidebaranimat');

    $('body').toggleClass('bodybarfixed');
  });

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      toggleSidebar();
    }
  });

  /* -------- COOKIE ------- */

  $('.btnblue ').click(function () {
    $('.cookiemain').hide();
  });

  /* -------- POUP HIDE/SHOW ------- */

  $('.uniquelisted a').click(function () {
    $(this).siblings('.unitlistexpand').css('display', 'flex').hide().fadeIn();
  });

  $('.uniqclosebtn').click(function () {
    $(this).parents('.unitlistexpand').css('display', 'none').show().fadeOut();
  });

  /* -------- LOGO Slider ------- */
  const swiperBrands = new Swiper('.brandslidebox', {
    spaceBetween: 150,
    centeredSlides: true,
    speed: 3000,
    loop: true,
    disableOnInteraction: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 6,

        spaceBetween: 60,
      },

      768: {
        slidesPerView: 3.8,

        spaceBetween: 40,
      },

      320: {
        slidesPerView: 2.5,

        spaceBetween: 20,
      },
    },
  });

  /* ----------- ---------- ------

    ///////// GLOBAL Slider /////////

    --------- ----------- ------- */

  const swiperGlobal = new Swiper('.globalSwiper', {
    speed: 1000,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      renderFraction(currentClass, totalClass) {
        return (
          `<span class="${currentClass}"></span>` +
          ` ` +
          `<span class="${totalClass}"></span>`
        );
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  /* -------- NEWS Slider ------- */

  const swiperNews = new Swiper('.newsswiper', {
    speed: 1000,

    loop: true,

    autoplay: {
      delay: 5000,
    },

    slidesPerView: 3,

    spaceBetween: 192,

    navigation: {
      nextEl: '.swiper-button-next',

      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      1440: {
        slidesPerView: 3,

        spaceBetween: 170,
      },

      1200: {
        slidesPerView: 3,

        spaceBetween: 70,
      },

      1024: {
        slidesPerView: 2,

        spaceBetween: 40,
      },

      768: {
        slidesPerView: 2,

        spaceBetween: 40,
      },

      640: {
        slidesPerView: 1.5,

        spaceBetween: 40,
      },

      480: {
        slidesPerView: 1.5,

        spaceBetween: 40,
      },

      375: {
        slidesPerView: 1,

        spaceBetween: 20,
      },
    },
  });

  /* -------- ACCORDIAN ------- */

  $('.accordion').click(function () {
    $(this).siblings('.accordianpanel').toggle();
    $(this).toggleClass('active');
  });

  /* ----------- ---------- ------

    ///////// POPUP MODEL /////////

    --------- ----------- ------- */

  $(function () {
    $(
      'section.leadership .clientpopup, section.leadership .teamlistbox .listbox li',
    ).on('click', function () {
      // Empty Popup
      $('.leaderpopupmodel').find('#member_content').html('');
      $('.leaderpopupmodel').find('#member_designation').text('');
      $('.leaderpopupmodel').find('.title h2').text('');
      $('.leaderpopupmodel').find('.popup-img img').attr('src', '');
      $('.leaderpopupmodel').find('#member_phone').text('');
      $('.leaderpopupmodel').find('#member_phone').attr('href', '');
      $('.leaderpopupmodel').find('#member_email').text('');
      $('.leaderpopupmodel').find('#member_email').attr('href', '');
      $('.leaderpopupmodel').find('#member_linkedin').attr('href', '');
      // Append Data
      const mData = $(this).find('data');
      $('.leaderpopupmodel')
        .find('#member_content')
        .html(mData.data('content'));
      $('.leaderpopupmodel')
        .find('#member_designation')
        .text(mData.data('designation'));
      $('.leaderpopupmodel').find('.title h2').text(mData.data('name'));
      $('.leaderpopupmodel')
        .find('.popup-img img')
        .attr('src', mData.data('img'));
      $('.leaderpopupmodel').find('#member_phone').text(mData.data('phone'));
      $('.leaderpopupmodel')
        .find('#member_phone')
        .attr('href', `tel:${mData.data('phone')}`);
      $('.leaderpopupmodel').find('#member_email').text(mData.data('email'));
      $('.leaderpopupmodel')
        .find('#member_email')
        .attr('href', `mailto:${mData.data('email')}`);
      let linkedIn = mData.data('linkedin').trim();
      linkedIn = linkedIn.length ? linkedIn : 'https://linkedin.com';
      $('.leaderpopupmodel').find('#member_linkedin').attr('href', linkedIn);
      $('.leaderpopupmodel').find('#member_linkedin').attr('target', '_blank');

      $('.leaderpopupmodel').css({
        transform: 'translateX(0px)',
        transition: 'all 1000ms ease-in-out',
      });
      //  $('.leaderpopupmodel').show();

      if (
        $('body').hasClass('popupbody') ||
        $('body').hasClass('contactbody')
      ) {
        $('body').removeClass('popupbody, contactbody');
      } else {
        $('body').addClass('popupbody, contactbody');
      }
    });

    /** **
    $('.clientpopup, .listbox li, .postitongrp-right a, .frmsubmitbtn').click(
      function () {
        $('.leaderpopupmodel').show();

        $('.careerpopup').show();

        $('.Thankupopup').show();

        if (
          $('body').hasClass('popupbody') ||
          $('body').hasClass('contactbody')
        ) {
          $('body').removeClass('popupbody, contactbody');
        } else {
          $('body').addClass('popupbody, contactbody');
        }
      },
    );
    ** */
    /** CF7 Form * */
    function cf7FieldsFocus() {
      $('.wpcf7-form input, .wpcf7-form textarea')
        .focus(function () {
          $(this).parent().siblings('label').addClass('has-value');
        })
        // blur input fields on unfocus + if has no value
        .blur(function () {
          const textVal = $(this).val();
          if (textVal === '') {
            $(this).parent().siblings('label').removeClass('has-value');
          }
        });
    }

    /** MC4WP * */
    function mc4wpFieldFocus() {
      $('.mc4wp-form input')
        .focus(function () {
          $(this).parent().find('label').addClass('has-value');
        })
        // blur input fields on unfocus + if has no value
        .blur(function () {
          const textVal = $(this).val();
          if (textVal === '') {
            $(this).parent().find('label').removeClass('has-value');
          }
        });
    }
    // CF7
    jQuery(document).ready(function () {
      // on focus
      cf7FieldsFocus();
      mc4wpFieldFocus();
    });

    /**
     * Careers pop-up
     */
    // $('.positionright').on('click', function () {
    //   const mData = $(this).find('data');

    //   $('.careerpopup #job_address').text(mData.data('location'));
    //   $('.careerpopup #job_title').text(mData.data('title'));
    //   $('.careerpopup #job_overview').html(mData.data('overview'));
    //   $('.careerpopup #job_responsibilities').html(
    //     mData.data('responsibilities'),
    //   );
    //   $('.careerpopup #job_competencies_and_attributes').html(
    //     mData.data('competencies_and_attributes'),
    //   );
    //   $('.careerpopup #job_designation').text(mData.data('title'));
    //   $('.careerpopup #job_form').html(mData.data('application_form'));

    //   $('.careerpopup').show();
    //   cf7FieldsFocus();
    // });

    $('.uniqclosebtn, .ctnthanksbtn').click(function () {
      $('.leaderpopupmodel').css({
        transform: 'translateX(100%)',
        transition: 'all 1000ms ease-in-out',
      });

      //  $('.leaderpopupmodel').hide();

      $('.careerpopup').hide();

      // CF7 Form clean
      $('.wpcf7-form input, .wpcf7-form textarea')
        .parent()
        .siblings('label')
        .removeClass('has-value');
      $('.Thankupopup').hide();

      $('body').removeClass('popupbody');
    });
  });

  $('.main-video').click(function () {
    if ($('#video_banner').get(0).paused) {
      $('#video_banner').attr('controls', 'controls');
      $('#video_banner').trigger('play');
      $('.video-playbtn').fadeOut(500);
    } else {
      $('#video_banner').removeAttr('controls');
      $('#video_banner').trigger('pause');
      $('.video-playbtn').fadeIn(500);
    }
  });

  /* --------- ----------- ---------
     // CAREER POPUP MODEL SLIDER //
     --------- ----------- --------- */
  $('.search-btn').click(function () {
    $('"html, body').animate(
      { scrollTop: $('#positionsection').scrollTop() },
      1000,
    );

    return false;
  });

  /* ----------- ---------- ------
     //////// NWES PORTFOLIE  ////
     -------- ----------- -------- */
  $(document).on('lity:open', function (event, instance) {
    /* eslint-disable no-console */
    console.log('Lightbox opened');
  });

  /* ----------- ---------- ------
     //////// Parallax text effect  ////
     -------- ----------- -------- */

  $(window).on('load scroll', function () {
    let parallaxElement = $('.animated-title');
    let parallaxQuantity = parallaxElement.length;
    window.requestAnimationFrame(function () {
      /* eslint-disable no-plusplus */
      for (let i = 0; i < parallaxQuantity; i++) {
        let currentElement = parallaxElement.eq(i);
        let windowTop = $(window).scrollTop();
        let elementTop = currentElement.offset().top;
        let elementHeight = currentElement.height();
        let viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5;
        let scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: `translate3d(0,${scrolled * -0.15}px, 0)`,
        });
      }
    });
  });
}); // // Doctype end
/* Accordion auto open */
$(document).ready(function () {
  if ($('.accordion').length) {
    let currURI = window.location.href;
    let indexHash = window.location.href.indexOf('#');
    let idAcco = currURI.substr(indexHash);
    $(`${idAcco} a`).siblings('.accordianpanel').toggle();
    $(`${idAcco} a`).toggleClass('active');
  }
});

/* --- NEWS PORTAL - FILTER --- */
function filterSelection(typeV = 'category', val = 'all', s = '') {
  $.ajax({
    url: siteAjax.ajax_url,
    data: { action: 'post_filter', stype: typeV, cval: val, search: s },
    type: 'POST',
    beforeSend() {
      $('.newsgrid-gallery').html('');
      $('.newsgrid-gallery').addClass('loading');
    },
    success(data) {
      $('.newsgrid-gallery').removeClass('loading');
      $('.newsgrid-gallery').html(data);
    },
  });
}

$(document).ready(function () {
  filterSelection();
});

/* Constant Contect - Newsletters Form */
$(document).ready(function () {
  $(window).keydown(function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  });
  if ($('.footer-main .wpcf7-form').length) {
    $('#step_1 .submitbtn').on('click', function () {
      let emailVal = '';
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          $(this).parent().find('input').val(),
        )
      ) {
        $(this).parent().addClass('error');
        return false;
      }
      $(this).parent().removeClass('error');

      $('#step_1').hide();
      $('.footer-main .title').hide();
      $('.footer-main .content').hide();

      if (jQuery('input[name=ctct-list-optin]').length) {
        jQuery('input[name=ctct-list-optin]').each(function () {
          if (jQuery(this).is(':checked')) {
            jQuery(`input[data-id=${jQuery(this).attr('id')}`).attr(
              'value',
              jQuery(`input[data-id=${jQuery(this).attr('id')}`).data('value'),
            );
          }
        });
      }

      $('#step_2').show();
      return true;
    });
    $('#step_2 input[type=submit]').on('click', function () {
      if (
        $.trim($(this).parents('#step_2').find('input[name=your-name]').val())
          .length === 0
      ) {
        $('#step_2 input[name=your-name]')
          .parents('.mc-field-group')
          .addClass('error');
        return false;
      }
      $('#step_2 input[name=your-name]')
        .parents('.mc-field-group')
        .removeClass('error');

      if (
        $.trim(
          $(this)
            .parents('#step_2')
            .find('input[name=your-company-name]')
            .val(),
        ).length === 0
      ) {
        $('#step_2 input[name=your-company-name]')
          .parents('.mc-field-group')
          .addClass('error');
        return false;
      }
      $('#step_2 input[name=your-company-name]')
        .parents('.mc-field-group')
        .removeClass('error');
      setTimeout(function () {
        $('.footer-main .title').show();
        $('.footer-main .content').show();
        $('#step_1').show();
        $('#step_2').hide();
      }, 1000);
      return true;
    });
    $('#step_2 .btnback').on('click', function () {
      $('.footer-main .title').show();
      $('.footer-main .content').show();
      $('#step_1').show();
      $('#step_2').hide();
      return false;
    });
  }
});
/* Constant Contect - Newsletters Form */

$('.newgallery-list ul > li').on('click', function () {
  $(this).parent('UL').find('li').removeClass('active');
  $(this).addClass('active');
  const stype = $(this).data('type');
  const sVal = $(this).data('value');
  const s = '';
  $(this).parents('.newgallery-list').find('.frmsearch-field').val('');
  filterSelection(stype, sVal, s);
});

$('.newgallery-list .searchbtns').on('click', function () {
  const s = $(this).parent().find('.frmsearch-field').val();
  const activeC = $('.newgallery-list ul').find('.active').data('value');
  filterSelection('search', activeC, s);
});

$('.newgallery-list .frmsearch-field').on('input', function () {
  const s = $(this).val();
  const activeC = $('.newgallery-list ul').find('.active').data('value');
  filterSelection('search', activeC, s);
});

/**
 * Auto-play video
 */
$('body').on('click touchstart', function () {
  const videoElement = document.getElementById('video-banner');
  if (videoElement !== null) {
    if (videoElement.playing) {
      // video is already playing so do nothing
    } else {
      // video is not playing
      // so play video now
      videoElement.play();
    }
  }
});

/* eslint-enable no-unused-vars,func-names */
